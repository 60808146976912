@import-normalize;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  margin: 0;
}

#root {
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: "password";
  font-style: normal;
  font-weight: 400;
  src: local("Password"), url(./fonts/Password/password.ttf) format("truetype");
}
