$screen-new-handheld-max: 361px;
$screen-old-handheld-max: 320px;
$screen-desktop: 840px;
$screen-mobile-max: 580px;

@mixin new-handheld {
  @media (max-width: #{$screen-new-handheld-max}) {
    @content;
  }
}

@mixin new-handheld-up {
  @media (min-width: #{$screen-new-handheld-max}) {
    @content;
  }
}

@mixin old-handheld {
  @media (max-width: #{$screen-old-handheld-max}) {
    @content;
  }
}

@mixin old-handheld-up {
  @media (min-width: #{$screen-old-handheld-max}) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: #{$screen-desktop}) {
    @content;
  }
}

@mixin desktop-up {
  @media (min-width: #{$screen-desktop}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$screen-mobile-max}) {
    @content;
  }
}

@mixin mobile-up {
  @media (min-width: #{$screen-mobile-max}) {
    @content;
  }
}

.desktop-only {
  @include mobile {
    display: none !important;
  }
}

.mobile-only {
  @include mobile-up {
    display: none !important;
  }
}

input.form-password {
  // font-family: "password";
  text-security: disc;
  -webkit-text-security: disc;
  -moz-text-security: disc;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* preset */
h1 {
  font-size: 24px;
}

p {
  margin-bottom: 0 !important;
}

/* home page */
.menu-background {
  min-width: 320px;
  min-height: 642px;

  background-image: url("./assets/img/unnamed.jpg") !important;
  /* Full height */
  width: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  text-align: center;
}

// @media screen and (min-width: 500px) {
//   .menu-background {
//     height: 100%;
//   }
// }

@include new-handheld-up {
  .menu-background {
    min-height: 100%;
  }
}

.menu-container {
  max-width: 361px;
  min-width: 320px;

  margin: 0 auto;
}

.home-table {
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  a {
    color: black;
    text-decoration: none;
  }
}

.home-table > a,
.home-table-items {
  flex: 0 calc(100% / 3);

  text-align: center;
}

.home-table-items p {
  font-size: 16px;
  font-weight: 700;
}

/* header */
.header-title-background {
  background-color: #3581df;
}

.header-title-container {
  height: 30px;
}

.text-center {
  text-align: center;
}

.margin-auto {
  margin: 0 auto;
}

.header-title {
  color: white;
  font-size: 24px;
}

/* footer */
.footer-container {
  a {
    text-decoration: none;
  }

  width: 100%;

  text-align: center;

  display: flex;
  justify-content: center;
}

/* common body styling */
.handheld-body-container,
.desktop-body-container,
.responsive-body-container,
.desktop-incomingdeliveriesbody-container {
  min-height: 360px;
  padding: 5px;
  margin: 0 auto;
}

.handheld-body-container {
  min-width: 320px;
  max-width: 361px;

  width: 100%;
}

.handheld-body-container-noscroll {
  height: 50px;
}

.desktop-body-container {
  min-width: 320px;
  max-width: 860px;

  width: 100%;
}

.desktop-incomingdeliveriesbody-container {
  min-width: 320px;
  max-width: 950px;

  width: 100%;
}

.responsive-body-container {
  min-width: 320px;
  max-width: 361px;

  @include new-handheld-up {
    max-width: 860px;
  }

  width: 100%;
}

.blockedorders-body-container {
  min-height: 360px;
  padding: 5px;
  margin: 0 auto;

  min-width: 320px;
  max-width: 1000px;

  width: 100%;
}

.error-message {
  color: red;
}

.no-button {
  background-color: white;
  border: none;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left !important;
}

.text-align-right {
  text-align: right !important;
}

.background-lightblue {
  background-color: lightblue;
}

.background-white {
  background-color: white;
}

.background-lightgreen {
  background-color: lightgreen;
}

.background-black {
  background-color: black;
}

.background-red {
  background-color: red;
}

.background-green {
  background-color: green;
}

.background-yellow {
  background-color: yellow;
}

.background-orange {
  background-color: orange;
}

.background-salmon {
  background-color: salmon;
}

.background-aquamarine {
  background-color: aquamarine;
}

.background-grey {
  background-color: grey;
}

.background-lightgrey {
  background-color: lightgrey;
}

.color-red {
  color: red;
}

.color-green {
  color: green;
}

.color-lightgreen {
  color: lightgreen;
}

.hidden {
  display: none !important;
}

.disabled {
  opacity: 0.45;
  pointer-events: none;
  user-select: none;
}

.margin-side-5 {
  margin: 0 5px;
}

.padding-2 {
  padding: 2px;
}

.padding-5 {
  padding: 5px;
}

.border-radius-5 {
  border-radius: 5px;
}

.border-1-solid-black {
  border: 1px solid black;
}

.width-full {
  width: 100%;
}

.home-table-items a {
  text-decoration: none;
}

.home-table-items a p {
  text-decoration: none;
  color: black;
}

.circle-buttons {
  width: 75px;
  height: 75px;
  border-radius: 50%;

  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.content-circle-buttons {
  width: 50px;
  height: 50px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.content-circle-buttons-content {
  font-size: 1.5em;
  color: white;
  margin: 0;
}

.footer-circle-buttons {
  width: 75px;
  height: 75px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  text-decoration: none;

  margin: 0 2.5px;
}

.unallocate-devicestatus-buttons {
  width: 75px;
  height: 75px;

  border: 1px solid black;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  text-decoration: none;

  padding: 25px 50px;
  margin: 0 2.5px;

  .unallocate-devicestatus-buttons-content {
    font-size: 1em;
  }
}

.weborders-page-container a,
.unallocatedorders-page-container a,
.stocktake-admin-blocksubcat-page-container a,
.page-container a {
  text-decoration: none;
}

.footer-circle-buttons-content {
  font-size: 1.5em;
  color: white;
  margin: 0;

  text-decoration: none;
}

.circle-buttons-content {
  color: white;

  font-size: 2em;

  margin: 0;
}

.main-container {
  height: 100%;

  display: flex;
  flex-direction: column;
}

.page-container {
  flex: 1;

  display: flex;
  flex-direction: column;

  overflow: auto;
}

.page-container > :first-child {
  flex: 1 0;
  overflow: auto;
}

.page-container > :last-child {
  flex: 0 0;
}

.popup-button-container {
  display: flex;
  justify-content: space-between;

  width: 100%;
}

.popup-button {
  padding: 2px 4px;

  border: 1px solid black;
  border-radius: 2px;
}

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

/* User Info component */
.user-info-container {
  width: 100%;
  max-width: 361px;

  padding: 5px 0px;
  margin: 0 auto;

  display: flex;
  flex-wrap: wrap;

  align-items: baseline;
}

.user-info-container p {
  flex: 0 0 60%;

  font-size: 16px;
  font-weight: 600;
  text-align: left;
  margin-left: 5px;
}

.user-info-container button {
  flex: 0 0 30%;

  font-size: 16px;
  font-weight: 600;
  padding: 3px 6px;

  background-color: transparent;
  border: 1px solid grey;
  border-radius: 5px;
}

@include old-handheld {
  .content {
    max-height: 280px;
  }
}

@media screen and (min-width: 350px) {
  .content {
    max-height: 380px;
  }
}
.content {
  overflow: scroll;
}

/* Sign In */
.signin-table {
  width: 100%;

  border-collapse: collapse;

  font-size: 20px;
}

.signin-table tr {
  height: 2em;

  td {
    div {
      display: flex;
      flex-direction: columm;
      align-items: center;

      p {
        font-size: 1rem;
        margin: 0;
        padding: 0;
      }

      > :first-child {
        flex: 0 0 30%;
      }

      > :nth-child(2) {
        flex: 0 0 70%;
      }
    }
  }
}

.signin-table input,
.signin-login-button {
  width: 100%;

  border-radius: 5px;
  border: 1px solid black;

  padding: 5px 10px;

  font-size: 16px;
  font-weight: 600;
}

.signin-login-button {
  background-color: #3581df;

  color: white;
}

.btnHover:hover {
  cursor: pointer;
}

/* EOD */
.EOD-table {
  width: 750px;

  border-collapse: collapse;

  font-size: 20px;
}

.EOD-table tr {
  height: 1.75em;
}

.EOD-table td {
  padding: 0 2.5px;
}

.EOD-table input {
  font-size: 20px;

  border: 0.5px solid black;
  outline: none;

  margin: 0px 5px;
  padding: 0px 5px;
}

.EOD-table input:focus,
.background-lightblue {
  background-color: lightblue;
}

.EOD-table hr {
  margin: 0;
}

.eod-option-row {
  display: flex;
}

.eod-option-row .home-table-items {
  flex: 0 0 100px;
  margin: 0 auto;
}

.popup-denomination-table tr td:first-child {
  width: 50px;
}

.popup-denomination-table tr td:nth-child(2) {
  width: 75px;
}

.popup-denomination-table tr td input {
  border: none;
  text-align: center;
}

/* Item Details */
.itemdetails-image-itemno-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.merchandising-itemno-container {
  font-size: 0.6em;
}

.merchandising-department-container {
  font-size: 0.6em;
}

.merchandising-stock-table {
}

.merchandising-stock-table,
.merchandising-stock-table tr,
.merchandising-stock-table th,
.merchandising-stock-table td {
  text-align: center;

  border: 1px solid black;
  border-collapse: collapse;
}

.merchandising-stock-table th,
.merchandising-stock-table td {
  padding: 0px;
}

.merchandising-stock-table {
  width: 100%;
  margin-top: 5px;
}

.merchandising-aisleno {
  text-align: center;
  padding: 0.5em 0;
  margin: 5px 0;

  background-color: yellow;
  border: 1px solid black;
}

.itemdetails-description-container {
  background-color: lightblue;
  padding: 1em 0px;
}

.merchandising-description-container {
  background-color: lightgrey;
  padding: 0.25em 0.1em;
  font-size: 0.85em;
}

.itemdetails-description-link {
  text-decoration: none;
  color: black;
}

.itemdetails-description-link:hover {
  color: black;
}

.itemdetails-price-container {
  border: 2px solid black;
  padding: 5px 0px;
  background-color: #f0f0f0;
  text-align: center;

  margin-top: 5px;
}

.itemdetails-soo-sot-container {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
}

.itemdetails-soo-button,
.itemdetails-sot-button {
  padding: 10px;
  margin-top: 10px;
  background-color: lightblue;

  border-radius: 5px;

  cursor: pointer;
}

.itemdetails-soh-button {
  background-color: lightblue;

  cursor: pointer;
}

.itemdetails-table,
.itemdetails-table tr,
.itemdetails-table th,
.itemdetails-table td {
  text-align: center;

  border: 1px solid black;
  border-collapse: collapse;
}

.itemdetails-table th,
.itemdetails-table td {
  padding: 5px;
}

.itemdetails-table {
  width: 100%;
  margin-top: 10px;

  th {
    svg {
      margin-left: 10px;

      &.no-margin {
        margin-left: 0;
      }
    }
  }

  tbody {
    svg {
      padding: 0 5px;
      margin: 0;
    }
  }
}

.itemdetails-table input {
  width: 100px;

  &.full-width {
    width: 100%;
  }
}

.itemdetails-sq-ns-wp-container {
  margin-top: 10px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.itemdetails-sq-ns-wp-button {
  padding: 0 5px;
}

/* Item Details Popup */

.itemdetails-sq-title,
.itemdetails-wp-title,
.itemdetails-ns-title {
  text-align: center;
  color: white;

  padding: 3px 6px;
}

.itemdetails-sq-input,
.itemdetails-wp-input,
.itemdetails-ns-input {
  width: 100%;
  margin-bottom: 10px;
}

/* Bulky Item Merch */
.bulkyitemmerch-menu-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bulkyitemmerch-menu-floor-storeroom-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* POD Code */
.podcode-menu-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.input-row,
.stocktake-reports-input-row,
.stocktake-create-input-row,
.storetransfer-input-row,
.itemsearch-itembarcode-input-row,
.staffmanage-edit-search-input-row,
.incomingdeliveries-input-row,
.two-input-row,
.money-input-row {
  display: flex;
  flex-direction: row;

  width: 100%;

  margin-bottom: 5px;
}

.input-row > :first-child {
  flex: 0 0 60%;
}

.input-row > :last-child {
  flex: 0 1 40%;
  width: 100%;
}

.itemsearch-itembarcode-input-row {
  align-items: center;

  & > :first-child {
    flex: 0 0 40%;
  }

  & > :nth-child(2) {
    flex: 1 1 50%;
    width: 100%;
  }

  & > :last-child {
    flex: 0 0 10%;
    width: 100%;
    aspect-ratio: 1;
  }
}

.staffmanage-edit-search-input-row {
  align-items: center;

  & > :first-child {
    flex: 0 0 30%;
  }

  & > :nth-child(2) {
    flex: 1 1 50%;
    width: 100%;
    margin-right: 10px;
  }

  & > :last-child {
    flex: 0 0 10%;
    width: 100%;
    aspect-ratio: 1;
  }
}

.podcode-edit-connote-container {
  display: flex;
  flex-direction: row;

  align-items: center;
}

.podcode-edit-connote-details {
  flex: 0 0 75%;
}

/* StockTake */
.stocktake-reports-input-row {
  align-items: center;
}

.stocktake-reports-input-row > :first-child {
  flex: 0 0 80%;
}

.stocktake-reports-input-row > :last-child {
  flex: 0 1 20%;
  width: 100%;
}

.stocktake-create-input-row > :first-child {
  flex: 0 0 30%;
}

.stocktake-create-input-row > :last-child {
  flex: 0 1 70%;
  width: 100%;
}

.money-input-row {
  align-items: center;
  > :first-child {
    flex: 0 0 27.5%;
  }

  > :nth-child(2) {
    flex: 0 0 2.5%;
  }
  > :last-child {
    flex: 0 1 70%;
    width: 100%;
  }
}

.stocktake-create-input-row {
  .money-input-container {
    display: flex;
    align-items: center;
    > {
      flex: 1 auto;
    }

    span.dollar-sign {
      margin-right: 5px;
    }

    input {
      width: 100%;
    }
  }
}

.incomingdeliveries-input-row {
  justify-content: space-between;
  align-items: center;

  > :first-child {
    flex: 0 0 40%;
  }

  > :last-child {
    flex: 0 0 55%;
    width: 100%;
  }
}

.two-input-row {
  justify-content: space-between;
  align-items: center;

  > :first-child,
  > :last-child {
    flex: 0 0 calc(50% - 5px);
  }
}

.stocktake-check-container {
  max-width: 500px;
  margin: 0 auto;
}

/* Blocked Orders */
.blockedorders-option-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.blockedorders-option-row .home-table-items {
  flex: 0;
  padding: 0 5px;
}

.blockedorders-order-table-col-1 {
  width: 20%;
}

.blockedorders-order-table-col-2 {
  width: 25%;
}

.blockedorders-order-table-col-3 {
  width: 25%;
}

.blockedorders-order-table-col-4 {
  width: 30%;
}

.blockedorders-content-container {
  max-width: 1200px;
  margin: 0 auto;
}

.blockedorders-order-table,
.blockedorders-address-table {
  width: 100%;
  margin: 0 auto;
}

.blockedorders-input-row {
  display: flex;
  flex-direction: row;

  align-items: center;
}

.blockedorders-input-row input,
.blockedorders-input-row select {
  height: 25px;

  margin: 0 10px;
}

.blockedorders-input-row {
  .from-to-text-container {
    input {
      width: 100px;
    }
  }
}

.itemsearch-input-row {
  display: flex;
  flex-direction: row;

  align-items: center;

  input {
    flex: 1 1 auto;
    margin-right: 10px;

    padding: 5px;
    border-radius: 5px;
  }

  div {
    flex: 0 0 50px;
  }
}

.itemsearch-index-row {
  margin: 5px 0;

  display: flex;
  flex-direction: row;

  align-items: center;

  button {
    flex: 0 0;

    padding: 2.5px 5px;
    border-radius: 5px;
  }

  p {
    flex: 1 1;
    align-self: center;

    text-align: center;
  }
}

.itemsearch-table-row {
  margin: 10px 0;
  height: 300px;
  overflow: auto;

  @include new-handheld-up() {
    height: 600px;
  }
}

/* Customer Service */
.customerservice-pickheader {
  background-color: lightgrey;
  width: 100%;
  padding: 5px 10px;
  margin: 20px 0;
  border-radius: 5px;
}

.customerservice-pickheader td {
  padding: 2.5px 5px;
}

/* Unallocated Orders */
.unallocatedorders-top-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  max-width: 1200px;
  width: 100%;
  margin: 10px auto 0;
}

.unallocatedorders-page-container {
  flex: 1;

  display: flex;
  flex-direction: column;

  overflow: auto;
}

.unallocatedorders-page-container > :first-child {
  flex: 0 0;
}

.unallocatedorders-page-container > :nth-child(2) {
  flex: 1 0;
  overflow: auto;
}

.unallocatedorders-page-container > :last-child {
  flex: 0 0;
}

.usericon-top-right {
  position: absolute;
  top: 2.5px;
  right: 5px;
}

@media screen and (min-width: 1500px) {
  .usericon-top-right {
    right: 40%;
  }
}

/* Phone Orders */
.phoneorders-table {
  max-width: 1200px;
  width: 100%;
}

.phoneorders-table th {
  padding: 5px 10px;
}

.phoneorders-table td {
  padding: 10px;
}

.phoneorders-table thead {
  color: white;
  background-color: black;
  font-size: 24px;
}

.phoneorders-table tbody,
.phoneorders-table tfoot {
  font-size: 20px;
}

.phoneorders-table tfoot {
  background-color: #cccc99;
}

.phoneorders-onlinestock {
  padding: 10px;
  margin-top: 10px;
}

/* Web Orders */
.weborders-option-row,
.weborders-manager-option-row {
  width: 100%;

  display: flex;
}

.weborders-option-row .content-circle-buttons {
  flex: 1;
  border: 1px solid black;
  margin: 0px 1.5px 10px;
}

.weborders-manager-option-row .content-circle-buttons {
  flex: 0 0 17%;
  border: 1px solid black;
  margin: 0px 1.5px 10px;
}

.weborders-page-container {
  flex: 1;
  max-width: 321px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;

  overflow: auto;
}

.weborders-top-row {
  margin: 10px 5px 0;
}

.weborders-page-container > :first-child {
  flex: 0 0;
}

.weborders-page-container > :nth-child(2) {
  flex: 1 1 30%;
  overflow: auto;
  min-height: 280px;
}

.weborders-page-container > :last-child {
  flex: 0 0;
  margin: 2px 0;
}

.weborders-printweblabels-container {
  display: flex;
  flex-direction: row;

  input {
    margin-right: 5px;
  }
}

/* Store Transfer */
.storetransfer-input-row > :first-child {
  flex: 0 0 40%;
}

.storetransfer-input-row > :last-child {
  flex: 0 1 60%;
  width: 100%;
}

/* Search Term Manage */
.searchtermmanage-top-row {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;

  width: 100%;
  margin: 10px auto 0;
}

/* Web Buffer */
.webbuffer-top-row {
  max-width: 1220px;
  width: 100%;
  margin: 10px auto 0;

  text-align: left;
}

/* Stock Take Admin */
.stocktake-admin-blocksubcat-top-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;

  max-width: 1200px;
  width: 100%;
  margin: 10px auto 0;

  .blockedorders-input-row {
    margin: 0 5px;
  }
}

.stocktake-admin-blocksubcat-page-container {
  flex: 1;

  display: flex;
  flex-direction: column;

  overflow: auto;
}

.stocktake-admin-blocksubcat-page-container > :first-child {
  flex: 0 0;
}

.stocktake-admin-blocksubcat-page-container > :nth-child(2) {
  flex: 1 0;
  overflow: auto;
}

.stocktake-admin-blocksubcat-page-container > :last-child {
  flex: 0 0;
}

/***** CALENDAR STYLING *****/
/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);

  .header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 115%;
    padding: 1em 0;
    border-bottom: 1px solid var(--border-color);

    @include desktop {
      font-size: 0.7em;
      padding: 1em 0;
    }

    .icon {
      cursor: pointer;
      transition: 0.15s ease-out;
      padding: 0.5em;

      &:first-of-type {
        @include desktop-up {
          margin-left: 1em;
        }
      }

      &:last-of-type {
        @include desktop-up {
          margin-right: 1em;
        }
      }
    }
  }

  .stock-target {
    background-color: lightblue;
    border-radius: 5px;
    text-align: center;
    margin: 10px 0;
    padding: 5px;
  }

  .days {
    text-transform: uppercase;
    font-weight: 400;
    color: var(--text-color-light);
    font-size: 70%;
    padding: 0.75em 0;
    border-bottom: 1px solid var(--border-color);

    @include desktop {
      .col {
        flex-grow: 0;
        // flex-basis: calc(100% / 7);
        // width: calc(100% / 7);
        flex-basis: calc((100% - 50px) / 7);
        width: calc((100% - 50px) / 7);

        &.col-title {
          flex-basis: 50px;
          width: 50px;
        }
      }
    }
  }

  .body {
    .cell {
      position: relative;
      height: 4em;

      p {
        font-size: 0.5em;
      }

      @include desktop {
        height: 2em;
      }

      overflow: hidden;
      cursor: pointer;
      background: var(--neutral-color);
      transition: 0.15s ease-out;
      font-size: 1.5em;

      border: 1px solid black;

      .number {
        position: absolute;
        font-size: 55%;
        line-height: 1;
        top: 2.5em;
        right: 0.4em;
        font-weight: 700;
        text-align: center;

        @include desktop {
          font-size: 0.35em;
          top: 1em;
          right: 0;
        }
      }

      .bg {
        font-weight: 700;
        line-height: 1;
        color: var(--main-color);
        opacity: 0;
        font-size: 8em;
        position: absolute;
        top: -0.2em;
        right: -0.05em;
        transition: 0.25s ease-out;
        letter-spacing: -0.07em;
      }

      .cell-desc {
        font-size: 0.5em;
        letter-spacing: -0.05em;
        text-align: left;
        position: relative;
        top: 10px;
      }
    }

    .selected {
      background-color: lightblue;
    }

    .today {
      border-left: 10px solid transparent;
      border-image: linear-gradient(45deg, #ff1a79 0%, #eb82b3 40%);
      border-image-slice: 1;
    }

    .pastday {
      background-color: lightgrey;
    }

    .unavailableday {
      background-color: lightcoral;
    }

    .bookedday {
      background-color: yellow;
    }

    .whiteday {
      background-color: white;
    }

    .yellowday {
      background-color: yellow;
    }

    .greenday {
      background-color: lightgreen;
    }

    .redday {
      background-color: lightcoral;
    }

    .greyday {
      background-color: lightgrey;
    }

    .orangeday {
      background-color: orange;
    }

    .row {
      border-bottom: 1px solid var(--border-color);
    }

    .disabled {
      color: var(--text-color-light);
      pointer-events: none;
    }

    .col {
      flex-grow: 0;
      // flex-basis: calc(100% / 7);
      // width: calc(100% / 7);

      flex-basis: calc(100% / 8);
      width: calc(100% / 8);

      @include desktop {
        flex-basis: calc((100% - 50px) / 7);
        width: calc((100% - 50px) / 7);

        &.col-title {
          flex-basis: 50px;
          width: 50px;
        }
      }
    }
  }
}

.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
  opacity: 0.05;
  transition: 0.5s ease-in;
}

.calendar .body .cell.today .bg {
  color: #ff1a79;
  opacity: 0.05;
}

.logout-link {
  text-decoration: underline;
  color: blue;
}

/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start,
.col-end {
  flex: 0 0 200px;

  @include desktop {
    flex: 0 0 50px;
  }

  text-align: center;

  border: 1px solid black;
  border-radius: 5px;
}

.col-center {
  flex: 1 0 !important;
  justify-content: center;
  text-align: center;
}

.incomingdeliveries-popup-container {
  font-size: 1em;

  @media screen and (max-width: 1000px) {
    font-size: 0.75em;
  }

  @include desktop {
    font-size: 0.5em;
  }

  .popup-header-row {
    max-width: 700px;
  }

  .delivery-details-table {
    border: 1px solid black;
    width: 100%;

    thead,
    tbody,
    th,
    tr,
    td {
      border: 1px solid black;

      padding: 5px;

      @include new-handheld {
        padding: 2px;
      }

      @include old-handheld {
        padding: 1px;
      }

      svg {
        padding: 5px;
        margin: 0;
      }
    }
  }

  .blockedorders-option-row {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .home-table-items,
    svg {
      flex: 0;
      padding: 0 5px;
    }
  }
}

.handheldpermission-body-container {
  width: 100%;
}

.handheldpermission-top-row {
  display: flex;
  flex-direction: row;
  justify-content: center;

  margin: 10px 0;

  .blockedorders-input-row {
    margin: 0 20px;
  }
}

.handheldpermission-info-container {
  display: flex;
  flex-direction: row;

  .handheldpermission-staffinfo-container {
    flex: 1;
  }
  .handheldpermission-staffallocatedto-container {
  }

  .handheldpermission-staffallocatedto-container {
    display: flex;
    justify-content: left;
    align-items: center;
    > :first-child {
      min-width: 200px;
      flex: 0 1;
    }
    > :last-child {
      flex: 0 0;
    }
  }
}

/* Print Receipt */
.printreceipt-top-row,
.printreceipt-top-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  &.simple-row {
    justify-content: space-around;
  }

  &.advanced-row {
    justify-content: space-evenly;
  }

  max-width: 1500px;
  width: 100%;
  margin: 10px auto 0;
}

.printreceipt-advancedsearch-button {
  color: #0000ff;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: grey;
  }
}

.printreceipt-page-container {
  flex: 1;

  display: flex;
  flex-direction: column;

  overflow: auto;
}

.printreceipt-page-container > :first-child,
.printreceipt-page-container > :nth-child(2) {
  flex: 0 0;
}

.printreceipt-page-container > :nth-child(3) {
  flex: 1 0;
  overflow: auto;
}

.printreceipt-page-container > :last-child {
  flex: 0 0;
}

.usericon-top-right {
  position: absolute;
  top: 2.5px;
  right: 5px;
}

.printreceipt-body-containter {
  display: flex;
  padding: 10px;
}
.printreceipt-body-containter > :first-child {
  flex: 1 1;
  overflow-y: scroll;
}

.printreceipt-body-containter > :nth-child(2) {
  flex: 1 1;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid black;
  margin-left: 10px;
}

@media screen and (min-width: 1500px) {
  .usericon-top-right {
    right: 40%;
  }
}

.merchandising-merchspot-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  div {
    flex: 1 1 50%;
    margin: 2.5px 0;
  }
}

.popup-radio-container {
  display: flex;
  align-items: center;

  input {
    margin-right: 5px;
  }

  label {
    margin-right: 10px;
  }
}

/* Perth warehouse */
.perth-warehouse-header {
  width: 100%;
  padding: 0 10px 5px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.perth-warehouse-header td {
  padding: 2.5px 5px;
}

/* Website CMS */
.popup-websitecms-image-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .desktop-container,
  .mobile-container {
    margin-bottom: 10px;
    margin-right: 10px;
    img {
      width: auto;
      max-width: 100%;
      max-height: 500px;
    }
  }
}

.pricechanges-top-row-container {
  display: flex;
  flex-direction: row;

  .pricechanges-option-row {
    flex: 1 1 50%;

    .content-circle-buttons {
      flex: 0 0 35%;
      border: 1px solid black;
      margin: 0px 1.5px 10px;
    }
  }

  .weborders-sohonly {
    flex: 1 1 50%;
  }
}

.nonstock-top-row-container {
  display: flex;
  flex-direction: row;

  .nonstock-option-row {
    display: flex;
    .content-circle-buttons {
      flex: 0 0 45%;
      border: 1px solid black;
      margin: 0px 1.5px 10px;
    }
  }
}

// Store List
.printstoreaddress-settings-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > :first-child {
    margin-right: 10px;
  }

  .printstoreaddress-settings-radio-container {
    margin-right: 10px;
    > :first-child {
      margin-right: 5px;
    }
  }
}

.popup-storelist-wfmkiosk-title-container {
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .wfmkiosk-title {
    padding: 0;
    margin: 0;
  }

  .wfmkiosk-action-container {
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    .popup-icon {
      flex: 0 0 33%;
      padding: 0 5px;
    }
  }
}

.dailysalesperformance-total-container {
  background-color: lightgrey;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;

  .dailysalesperformance-total {
    flex: 0 0 calc(100% / 3);
    text-align: center;

    padding: 2.5px;
  }
}

.no-highlight {
  user-select: none;
  -webkit-user-select: none;
}

textarea {
  resize: none;
  padding: 1px 3px;
}

.storeaudittool-image {
  width: 100%;
}

.storeaudittool-fileupload-container {
  display: inline-block;
  position: relative;
  input {
    width: 100%;
  }

  .storeaudittool-deleteimage-icon {
    position: absolute;
    top: 0;
    right: 0;

    padding: 7.5px;

    background-color: rgba(255, 255, 255, 0.5);
    color: black;

    &:hover {
      color: red;
    }
  }
}

.storeaudittool-camera-preview {
  video,
  img {
    width: 100%;
  }

  &.snapped {
    border: 1px solid lightgrey;
    border-radius: 5px;
  }
}

.visibility-hidden {
  visibility: hidden;
}

.storecompliancetool-store-checkbox-container {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    width: 100%;
    font-size: 1.2em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    label {
      width: 100%;
    }
  }
}

.padding-5 {
  padding: 5px;
}

input[type="date"]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}
input[type="date"]:focus::-webkit-datetime-edit {
  color: black !important;
}

.receipt-preview-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  border: 1px solid grey;

  overflow: auto;

  pre {
    padding: 0;
    margin: 0;
  }
}
